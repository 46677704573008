// import {ServerEventComponent} from "./utils/ServerEventComponent";
import {useAPI} from "../api/v2/apiV2";
import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {useUserService} from "../services/userService/UserServiceProvider";
import RoleModalV2 from "./modal/RoleModal_v2";
import {Outlet, Route, Routes, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import LoadingSpinner from "../uiElements/LoadingSpinner";
import {ThemeProvider, useTheme} from "styled-components";
import ThemeGlobal from "../theme/ThemeGlobal";
import ThemeAM from "../theme/ThemeAM";
import ThemePM from "../theme/ThemePM";
import ThemeAdmin from "../theme/ThemeAdmin";
import ThemeSuperuser from "../theme/ThemeSuperuser";
import UserDashboard from "./userDashboard/UserDashboard";

function DashboardV2() {

    const location = useLocation();
    const navigate = useNavigate();
    const api = useAPI();
    const userService = useUserService();

    const [theme, setTheme] = useState(ThemeGlobal);
    // const serverEvents = ServerEventComponent.getInstance();

    console.log(location)

    const outletContext = useOutletContext();
    if(outletContext){
        console.log("OutletContext", outletContext)
    }

    const logout = () => {
        api.logout().then((result) => {
            if (result.status === 200){
                // navigate("/login")
                navigate("/")

            }
            else
                throw Error("Error")
        }).catch((error) => {
            console.error(error)
            // navigate("/login")
            navigate("/")
        })
    }

	/**
	 * Function to update Theme according to the activeRole
	 */
    const updateTheme = () => {
        if (userService.activeRole) {
            switch (userService.activeRole.role) {
                case "AM":
                    setTheme(ThemeAM);
                    break;
                case "PM":
                    setTheme(ThemePM);
                    break;
                case "ADMIN":
                    setTheme(ThemeAdmin);
                    break;
                case "SUPERUSER":
                    setTheme(ThemeSuperuser);
                    break;
                default:
                    setTheme(ThemeGlobal);
            }
        } else {
            setTheme(ThemeGlobal);
        }
    }

    useEffect(() => {
        if (userService.init) {
            updateTheme()
        }
    }, [userService.init]);

    useEffect(() => {
        updateTheme()
    }, [userService.activeRole])

    useLayoutEffect(() => {
        return () => {
            console.log("Unmount Dashboard")
            userService.reset();
        }
    }, [])

    if (userService.init) {
        if (userService.activeRole) {
            if (userService.activeRole && userService.activeClient) {
                return (
                    <ThemeProvider theme={theme}>
                        <UserDashboard
                            role={userService.activeRole}
                        />
                    </ThemeProvider>
                )
            } else if (userService.activeRole.role === "SUPERUSER") {
                console.log("SUPERUSER has no Client")
                return (
                    <ThemeProvider theme={theme}>
                        <UserDashboard
                            role={userService.activeRole}
                        />
                    </ThemeProvider>
                )
            } else {
                console.log(userService.activeRole.role, userService.activeClient)
                console.error("ERROR in UserService", userService.activeRole)
            }
        } else {
            return (
                <RoleModalV2
                    show={true}
                    roles={userService.user.roles}
                    logout={logout}
                />
            )
        }
    } else {
        return (
            <div style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: "center",
                alignItems: "center"
            }}>
                <LoadingSpinner title={"Loading..."}/>
            </div>
        )
    }
}

export default DashboardV2;