import React, {useEffect, useMemo, useState} from "react";
import {useAPI} from "../../../../api/v2/apiV2";
import {PAGE} from "../RuleDashboard";
import EnumForm from "./EnumForm";
import EnumSidebar from "./EnumSidebar";


function EnumPage(){

    const api = useAPI();


    const [enums, setEnums] = useState({});
    const [selectedEnum, setSelectedEnum] = useState("");

    useEffect(() => {
        console.log(selectedEnum)
    }, [selectedEnum])

    useMemo(() => {
        api.rules.getEnums().then(response => {
            //console.log(response)
            setEnums(response.enumerations)
        }).catch(err => {

        })
    }, [])

    return(
        <div className={"w3-row"}>
            <EnumSidebar className={"w3-third"} selectedEnum={selectedEnum} setSelectedEnum={setSelectedEnum} data={enums}></EnumSidebar>
            <EnumForm className={"w3-twothird"} selectedEnum={selectedEnum} page={PAGE.ENUM_PAGE} data={enums}></EnumForm>
        </div>
    )
}

export default EnumPage;