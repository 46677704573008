import {useUserService} from "../../services/userService/UserServiceProvider";
import React, {useMemo, useState} from "react";
import {SidebarItems} from "../SidebarItemsConfig";
import styles from "../global/css/mainstyle.module.css";
import Sidebar from "../global/sidebar/Sidebar";
import NavigationBar from "../global/navbar/NavigationBar";
import UserPages from "./UserPages";

import {SSEProvider} from "react-hooks-sse";
import {SseSource} from "../../api/v2/ServerSendEvents";

function UserDashboard() {

    const userService = useUserService();
    // const theme = useTheme();

    const [sidebarItems, setSidebarItems] = useState(null);

    const getUserSideBarItems = () => {

        if (userService.activeRole.role === "SUPERUSER") {
            console.log("Render Items for SUPERUSER")
            let menuItems = new SidebarItems(null, userService.activeRole.role)
            setSidebarItems(menuItems)
        } else {
            if (userService.activeClient && userService.activeClient.instance_settings.enabled_features) {
                // console.log(userService.activeClient)
                let menuItems = new SidebarItems(
                    userService.activeClient.instance_settings.enabled_features,
                    userService.activeRole.role
                )
                setSidebarItems(menuItems);
            } else {
                console.warn("Instance-Settings are missing")
            }
        }
    }

    useMemo(() => {
        getUserSideBarItems()
    }, [userService.activeRole])

    return (
        <>
            <main className={styles.containerMain}>
                <div className={styles.containerLeft}>
                    <Sidebar
                        selectedRole={userService.activeRole.role}
                        menuItems={sidebarItems}
                    />
                </div>
                <div className={styles.containerMiddle}>
                    <SSEProvider source={() => SseSource(userService.getToken())}>
                        <NavigationBar/>
                    </SSEProvider>

                    {/*<WebSocketProvider url={"ws://"+window.location.hostname+"/ws"}>*/}
                    {/*    <NavigationBar/>*/}
                    {/*</WebSocketProvider>*/}

                    <div className={styles.mainContent}>
                        {/*<SSEProvider endpoint={http.defaults.baseURL+"/events/updates"}>*/}
                        <UserPages />
                    </div>

                </div>
            </main>
        </>
    )

}

export default UserDashboard;
