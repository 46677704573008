/* eslint-disable */
import React,{Component} from "react";
import API from "../../../api/api";
import moment from 'moment'

import styled, {useTheme} from "styled-components";
import TemplateDiv from "../StyledPages";
import {AlertWarningWithDissmiss} from "../../utils/Warnings";

import styles from "../../global/css/masterdata.module.css";
import "../../global/css/mddata.css";

import {toast} from "react-toastify";
import InstanceSettings from "./InstanceSettings";
import {EventComponent as GlobalDataSource} from "../../utils/EventComponent";
import {useUserService} from "../../../services/userService/UserServiceProvider";

const ContentDiv = styled.div`
  width: 100%;
  box-sizing: inherit !important;
  min-height: calc(100vh - 50px);
  //background-image:url("images/background_3-progressive.jpeg");  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
`;

function textAreaAdjust(element) {
    element.style.height = "1px";
    element.style.height = (25+element.scrollHeight)+"px";
}

function textAreaAdjustEvent(event) {
    textAreaAdjust(event.target)
}

function MasterData(props){

    const theme = useTheme();
    const {activeRole, activeClient} = useUserService();

    return(
        <MasterDataOld role={activeRole} roleColor={theme.palette.primary} client={activeClient}/>
    )

}

export class MasterDataOld extends Component {

    constructor(props) {
        super(props);
        this.api = new API();

        this.state = {
            Users: this.getUsers(),
            Properties: this.getProperties(),
            Deliveries: this.getDeliveries(),
            Clients: (this.props.role.role==="SUPERUSER")?this.getClients():[],
            role: this.props.role.role,
            roleColor: this.props.roleColor,
            sidebarSelect: undefined,
            selectedId: undefined,
            dataGetters:{
                Clients: this.getClients,
                Users: this.getUsers,
                Properties: this.getProperties,
                Deliveries: this.getDeliveries
            }


        }

        if(this.state.sidebarSelect === undefined){
            if(this.state.role === "SUPERUSER")
                this.state.sidebarSelect = "Clients"
            if(this.state.role === "AM")
                this.state.sidebarSelect = "Deliveries"
            if(this.state.role === "CO")
                this.state.sidebarSelect = "Users"
            if(this.state.role === "SPV")
                this.state.sidebarSelect = "Users"
            if(this.state.role === "ADMIN")
                this.state.sidebarSelect = "Users"
        }
        this.state.dataGetters[this.state.sidebarSelect]()
        this.sidebarSelectGetter = this.sidebarSelectGetter.bind(this);
        this.sidebarSelectSetter = this.sidebarSelectSetter.bind(this);
        this.idSetter = this.idSetter.bind(this);

        this.getClients = this.getClients.bind(this)
        this.getUsers = this.getUsers.bind(this)
        this.getProperties = this.getProperties.bind(this)
        this.getDeliveries = this.getDeliveries.bind(this)
        this.stateGetter = this.stateGetter.bind(this)
        this.stateSetter = this.stateSetter.bind(this)

    }

    stateGetter = (key) => {
        return this.state[key]
    }

    stateSetter = (key, value) => {
        let ts = this.state
        ts[key]=value
        this.setState(ts)
    }

    getUsers = () =>{
        this.api.masterData.user.get(this.api.getCookie("jwt"),{}).then(response=>{
            this.setState({Users: response.data})
        })
    }
    getDeliveries = () =>{
        this.api.masterData.delivery.get(this.api.getCookie("jwt"),{}).then(response=>{
            this.setState({Deliveries: response.data})
        })
    }
    getClients = () => {
        this.api.masterData.client.get(this.api.getCookie("jwt"), {}).then((response) => {
            this.setState({Clients:response.data})
        })
    }
    getProperties = () => {

        this.api.masterData.property.get(this.api.getCookie("jwt"), {}).then((response) => {
            response.data.map(x=>{
                if (x.assigned_users.length != 0)
                    x.assigned_users.map(y=>{
                        y.key = Math.random()
                    })
            })
            this.setState({Properties:response.data})
        })
    }
    sidebarSelectSetter = (value) =>{
        this.setState({sidebarSelect:value})
        this.state.dataGetters[value]()

    }
    sidebarSelectGetter = () =>{
        return this.state.sidebarSelect
    }

    idSetter = (id) =>{

        this.setState({selectedId:id})
    }

    render() {
        return(
            <TemplateDiv>
                <MDSidebar idSetter = {this.idSetter} stateGetter = {this.stateGetter} sidebarSelectSetter = {this.sidebarSelectSetter} sidebarSelectGetter = {this.sidebarSelectGetter} roleColor = {this.props.roleColor}></MDSidebar>
                <MDCardContainer role={this.state.role} stateSetter = {this.stateSetter} client = {this.props.client} selectedId = {this.state.selectedId} roleColor = {this.props.roleColor} stateGetter = {this.stateGetter}></MDCardContainer>
                {/*<MDTabs roleColor = {this.props.roleColor} modalUpdater = {this.state.modalUpdater} role={this.props.role} dataGetterSetter = {this.dataGetterSetter} setFormData = {this.formDataSetter}></MDTabs>
                <MDModal modalDataUpdater = {this.modalDataUpdater} role={this.props.role} dataGetter = {this.state.dataGetters} myClient={this.state.myClient} formData = {this.state.formData}></MDModal>
                */}
            </TemplateDiv>
    );
    }
}

function searchSelect(ev) {
    if(ev === undefined) return 0
    let e = ev.target
    if(document.getElementById("mdSelectContainer")===null) return 0;
    document.getElementById("mdSelectContainer").children.map(x=>{
        if( x.innerText.toLowerCase().indexOf(e.value.toLowerCase()) !== -1)
            x.style.display = "block";
        else
            x.style.display = "none";
    })
    return undefined;
}


const SideBarElement = styled.div`
      cursor:pointer;
      margin-bottom:16px;
      width: calc(100% - 22px) !important;
      border-left: 2px solid #ff584c;
      background: ${props =>{return "linear-gradient(to left, #f2f2f2 50%, "+props.roleColor+"  50%) right"}}; 

      background-size: 198%;
      transition: .2s ease-in-out;
      &:hover {
        background-color: ${props =>{return props.roleColor+" !important"}};
        background-position:left !important;
    
        color:white;
      } ;
      &[selected] {
        background-color: ${props =>{return props.roleColor+" !important"}};
        background-position:left !important;
    
        color:white;
      } ;

    `;

class MDSidebar extends Component{
    constructor(props) {
        super(props)
        this.state = {
            showDeactivated: false,
            sidebarSelectDefaultValue: this.props.sidebarSelectGetter(),

        }

    }


    setIdSetter = (e)=>{
        this.props.idSetter(e.target.id)
        document.querySelectorAll(".selectBarElement").map(x=>{
            if(e.target.id === x.id)
                x.setAttribute("selected","")
            else
                x.removeAttribute("selected")
        })
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState!==this.state){


            if(document.querySelectorAll(".selectBarElement").filter(x=>x.style.display!="none").length>0)
                document.querySelectorAll(".selectBarElement").filter(x=>x.style.display!="none")[0].click()
        }
    }
    componentDidMount() {

        setTimeout(() => {if(document.querySelectorAll(".selectBarElement").filter(x=>x.style.display!=="none")[0]!==undefined)document.querySelectorAll(".selectBarElement").filter(x=>x.style.display!=="none")[0].click();}, 400);

    }

    clickFirst = ()=>{
        setTimeout(() => {if(document.querySelectorAll(".selectBarElement").filter(x=>x.style.display!=="none").length>0)document.querySelectorAll(".selectBarElement").filter(x=>x.style.display!=="none")[0].click();}, 400);
    }

    handleKeyDown = (e)=>{
        if (e.key === 'Enter') {
            this.clickFirst()
        }
    }

    drawSidebarElements = ()=>{

        let s = this.props.sidebarSelectGetter();
        if(this.props.stateGetter(s)=== undefined)
            return ""

        switch(s){
            case "Clients":
                return this.props.stateGetter(s).map(x=>
                    (<SideBarElement style={{display:!this.state.showDeactivated && x.deactivated?"none":"block"}} roleColor={this.props.roleColor} id={x.id} onClick={this.setIdSetter} className="w3-large selectBarElement w3-bar-item">{x.name} {!x.deactivated?"":<i style={{lineHeight:"1.5em", float:"right"}} className="fas fa-ban"></i>}</SideBarElement>)
                )
                break;
            case "Users":
                return this.props.stateGetter(s).map(x=>
                    (<SideBarElement style={{display:!this.state.showDeactivated && x.deactivated?"none":"block"}} roleColor={this.props.roleColor} id={x.id} onClick={this.setIdSetter} className="w3-large selectBarElement w3-bar-item">{x.first_name} {x.last_name} {x.verified?"":<i style={{lineHeight:"1.5em", float:"right"}} className="fa fa-envelope"></i>} {!x.deactivated?"":<i style={{lineHeight:"1.5em", float:"right"}} className="fas fa-ban"></i>}</SideBarElement>)
                )
                break;

            case "Deliveries":
                return this.props.stateGetter(s).map(x=>
                    (<SideBarElement style={{display:!this.state.showDeactivated && x.deactivated?"none":"block"}} roleColor={this.props.roleColor} id={x.id} onClick={this.setIdSetter} className="w3-large selectBarElement w3-bar-item">{x.fond_name} {!x.deactivated?"":<i style={{lineHeight:"1.5em", float:"right"}} className="fas fa-ban"></i>}</SideBarElement>)
                )
                break;

            case "Properties":
                return this.props.stateGetter(s).map(x=>
                    (<SideBarElement style={{display:!this.state.showDeactivated && x.deactivated?"none":"block"}} roleColor={this.props.roleColor} id={x.id} onClick={this.setIdSetter} className="w3-large selectBarElement w3-bar-item">{x.property_name} ({x.property_id_receiver}) {!x.deactivated?"":<i style={{lineHeight:"1.5em", float:"right"}} className="fas fa-ban"></i>}</SideBarElement>)
                )
                break;


        }


        return
    }
    getSidebarOptions = ()=>{
        let r = this.props.stateGetter("role")
        let out = []
        switch (r) {
            case"SUPERUSER":
                out.push(<option value="Clients">‍  ‍   Clients</option>)
            case "ADMIN":
                out.push(<option value="Users">‍  ‍   Users</option>)


            case "AM":
                out.push( <option value="Properties">‍  ‍   Properties</option>)
                out.push( <option value="Deliveries">‍  ‍   Deliveries</option>)
            break;
        }
        return out;
    }


    render(){

        let entities = this.props.stateGetter(this.props.sidebarSelectGetter())
        let deactivated = (entities !== undefined) ? (entities.filter(entity => entity.deactivated == true).length) : undefined;

        // if(entities !== undefined){
        //     entities.filter(entity => entity.deactivated == false).length)
        // }

        // console.log(this.props.stateGetter(this.props.sidebarSelectGetter()))
        // let deactivated = this.props.stateGetter(this.props.sidebarSelectGetter()).filter(entity => entity.deactivated);

        // console.log(deactivated)

        return (
        <div className="w3-bar-block mdsidebar">
            <div className="w3-bar-item">
                <select className="w3-round" defaultValue={this.state.sidebarSelectDefaultValue} name="option" onChange = {(event)=>{this.props.sidebarSelectSetter(event.target.value);this.clickFirst();document.getElementById("searchSelectInput").value=""}} id="mdselect" style={{backgroundColor: '#f2f2f2', borderColor:  this.props.roleColor, borderWidth: '2px', margin: '8px 4px', padding: '3px', width: '170px'}}>
                    {this.getSidebarOptions()}
                </select>
                <button className="w3-round w3-large" id={"new"} onClick={this.setIdSetter} style={{border: '2px solid '+ this.props.roleColor, margin: '8px 0px 8px 0px', float: 'right', padding: '1px 9px'}}>+</button>
                <input className="w3-round w3-large" type="text"  onKeyDown={this.handleKeyDown}  placeholder="  🔎" style={{backgroundColor: '#f2f2f2', border: '2px solid '+ this.props.roleColor, margin: '8px 4px', padding: '2px', width: 'calc(100% - 4px)'}} onInput={searchSelect} id="searchSelectInput" />
            </div>
            <div className={"w3-bar-item"} style={{paddingLeft:"32px"}}>
                <input type={"checkbox"} className={"w3-check"} value={this.state.showDeactivated} onClick={(e)=>{this.setState({showDeactivated:e.target.checked})}}/> <label>Show Deactivated {(deactivated !== undefined) ? "("+deactivated+")" : ""} </label>
            </div>
            <div id="mdSelectContainer" className="w3-bar-block mdSelectContainer">

                {this.drawSidebarElements()}
            </div>
        </div>
    );
    }
}


class MDUserInput extends Component {

    constructor(props) {
        super(props)
        let tempInputUsers = []
        if(props.dbUsers !== undefined){
            tempInputUsers = []
            props.dbUsers.map(x=>{
                if(x.user !== null)
                    tempInputUsers.push({
                        userId:x.user.id,
                        role:x.role,
                        key:x.key,
                        assigned_from: x.assigned_from,
                        assigned_till: x.assigned_till,
                        category: (moment(x.assigned_from)>moment())?"future":(moment(x.assigned_till)<moment())?"past":"current",
                    })
            })
        }
        this.state = {
            updateUserInput: props.updateUserInput,
            globalUsers: props.globalUsers,
            inputUsers: tempInputUsers,
            maxDate: (props.maxDate === null ? "" : props.maxDate)
        }
        this.addUserElem = this.addUserElem.bind(this);
        this.setStateUsers = this.setStateUsers.bind(this);
    }
    addUserElem = ()=>{
        let tempstate = this.state.inputUsers;
        tempstate.unshift({userId:"", role:"", assigned_from:"", assigned_till:"", category:"current", key:Math.random()})
        this.setState({inputUsers:tempstate})
    }
    setStateUsers = (e) =>{
        let tempstate = this.state.inputUsers;
        tempstate.find(x=>x.key == e.target.id).userId = e.target.value;
        if(this.getGlobalUserByID(e.target.value).roles.length ===1)
            tempstate.find(x=>x.key == e.target.id).role = this.getGlobalUserByID(e.target.value).roles[0].role

        this.setState({inputUsers:tempstate})
    }
    setFrom = (e) =>{
        let tempstate = this.state.inputUsers;
        tempstate.find(x=>x.key == e.target.id).assigned_from = e.target.value;
        this.setState({inputUsers:tempstate})
    }
    setTill = (e) => {
        let tempstate = this.state.inputUsers;
        tempstate.find(x=>x.key == e.target.id).assigned_till = e.target.value;
        this.setState({inputUsers:tempstate})
        // this.setState({maxDate:e.target.value})
    }
    removeUserEntry = (e) => {
        let tempstate = this.state.inputUsers;
        tempstate.splice(e.target.id,1)
        this.setState({inputUsers:tempstate})
    }
    setStateRole = (e) => {
        let tempstate = this.state.inputUsers;
        tempstate.find(x=>x.key == e.target.id).role = e.target.value;
        this.setState({inputUsers:tempstate})
    }
    getGlobalUserByID = (id) => {
        return this.state.globalUsers.find(x=>x.id===id)
    }
    componentDidUpdate(prevProps) {
        if(this.props.maxDate !== prevProps.maxDate){
            this.setState({maxDate:this.props.maxDate})
        }
    }


    generateUserLines = (userlist, mode)=>{
        let hideStatus = ""
        if(mode=="past")
            hideStatus = " w3-hide"
        return userlist.map((x)=>{
            if(x.userId!==null){
                let key = x.key

                return (
                    <div className={"w3-container userline "+ "userline-"+mode + hideStatus}>
                        <select   disabled={this.props.editDisabled}  required={true} className={"mdInput w3-border"} style={{"marginRight":"5px","width":"30%", "display":"initial"}} id = {key} onInput={this.setStateUsers} value = {x.userId}>
                            <option selected={x.userId===""?true:false} value={""} disabled={true}>Select User</option>

                            {this.state.globalUsers.filter((y)=>(y.roles.length>1)? true : y.roles[0].role!=="ADMIN").map((y,i)=>{
                                return(<option value = {y.id} > {y.email}</option>)
                            })}
                        </select>
                        <select  disabled={this.props.editDisabled}  required={true} className={"mdInput w3-border"} style={{"marginRight":"5px","width":"20%", "display":"initial"}} id = {key} value = {x.role} onInput={this.setStateRole} >
                            <option selected={
                                x.userId !==""?
                                    (this.getGlobalUserByID(x.userId).roles.length===1?false:true):
                                    true
                            } value={""} disabled={true}>Select Role</option>
                            {(this.getGlobalUserByID(x.userId)!==undefined)?this.getGlobalUserByID(x.userId).roles.map(y=>{if(y.role!=="ADMIN")return <option selected={y.role==x.role} value = {y.role} >{y.role}</option>}):""}
                        </select>
                        <input disabled={this.props.editDisabled} className={" mdInput w3-border"} style={{"marginRight":"5px","width":"20%", "display":"initial"}} type={"date"} onInput={this.setFrom} id = {key} value = {x.assigned_from} required/>
                        <input disabled={this.props.editDisabled} className={" mdInput w3-border"} style={{"marginRight":"5px","width":"20%", "display":"initial"}} type={"date"} onInput={this.setTill} id = {key} value = {x.assigned_till}/>

                    </div>)
            }
        })

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return true
    }


    render(){
        let iu = this.state.inputUsers;
        if(this.props.dbUsers.length>0)
            if(this.props.dbUsers[0].category != undefined){
                iu.map(x=>{
                    if(x.category !== this.props.dbUsers.find(y=>y.id==x.id).category)
                    x.category= (moment(x.assigned_from)>moment())?"future":(moment(x.assigned_till)<moment())?"past":"current";
                })
            }
        //let iu = this.props.dbUsers

        //iu.map(x=>{x.category= (moment(x.assigned_from)>moment())?"future":(moment(x.assigned_till)<moment())?"past":"current"; return x})
        let today = moment()
        let assignments = {
         current : [],
         future : [],
         past : [],
        }
        iu.forEach(x=>{
            assignments[x.category].push(x)
        })

        function toggleUserList (mode){
                let chevState = document.getElementById("chevron-"+mode).classList.contains("fa-chevron-up")
            if(chevState){
                document.getElementById("chevron-"+mode).classList.remove("fa-chevron-up")
                document.getElementById("chevron-"+mode).classList.add("fa-chevron-down")
                document.getElementsByClassName("userline-"+mode).forEach(x=>{
                    x.classList.remove("w3-hide")
                })
            }
        else{
                document.getElementById("chevron-"+mode).classList.remove("fa-chevron-down")
                document.getElementById("chevron-"+mode).classList.add("fa-chevron-up")
                document.getElementsByClassName("userline-"+mode).forEach(x=>{
                    x.classList.add("w3-hide")
                })
            }
        }

        return (
            <div id={"MDUserInput"}>
                <div className={"w3-container"}>
                    <div style={{display:this.props.editDisabled?"none":"inline-block"}} onClick={()=>{if(!this.props.editDisabled)this.addUserElem()}} className={"w3-button w3-border"}>Add User +</div>
                </div>

                <div onClick={()=>toggleUserList("current")} className = "w3-container"><h5><i className={"fa fa-chevron-down"} id={"chevron-current"}></i> Current:</h5></div>

                {
                    this.generateUserLines(assignments.current, "current")
                }
                {()=>{if(assignments.future.length > 0)return <div onClick={()=>toggleUserList("future")} className = "w3-container"><h5><i className={"fa fa-chevron-down"} id={"chevron-future"}></i> Future:</h5></div>}}

                {
                    this.generateUserLines(assignments.future, "future")
                }

                <div onClick={()=>toggleUserList("past")} className = "w3-container"><h5><i className={"fa fa-chevron-up"} id={"chevron-past"}></i> Past:</h5></div>

                {
                    this.generateUserLines(assignments.past, "past")
                }

            </div>
        )
    }
}


class MDCardContainer extends Component{
    constructor(props) {
        super(props)

        this.api = new API();

        this.state = {
            editDisabled: true,
            cardData :{
                type:this.props.stateGetter("sidebarSelect"),
                id: this.props.selectedId,
                data: {}
            }
        }
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
    }
    toggleSubChecks = () => {
        document.querySelectorAll(".topCheck").map(x=>{


            x.checked?
                document.querySelectorAll("#"+x.name+" > .subCheck > div > input").map(y => y.disabled = false)
                :
                document.querySelectorAll("#"+x.name+" > .subCheck > div > input").map(y => y.disabled = true)
        })
    }
    checkFeatureChecks = (d, feature) =>{

        if(d.instance_settings===undefined || d.instance_settings===null)
            return false
        if(d.instance_settings.enabled_features===undefined)
            return false
        return d.instance_settings.enabled_features.indexOf(feature) !== -1
    }

    checkSubChecks = (d, group, sub) =>{

        if(d.instance_settings===undefined || d.instance_settings===null)
            return false

        if(d.instance_settings.upload_types===undefined)
            return false

        if(d.instance_settings.upload_types[group]===undefined)
            return false
        return d.instance_settings.upload_types[group].indexOf(sub) !== -1
    }
    checkTopChecks = (d, group) =>{

        if(d.instance_settings===undefined || d.instance_settings===null)
            return false

        if(d.instance_settings.upload_types===undefined)
            return false

        if(d.instance_settings.upload_types[group]===undefined)
            return false
        return Object.keys(d.instance_settings.upload_types).indexOf(group)!==-1
    }
    getGlobalUserByID = (id)=>{
        return this.props.stateGetter("Users").find(x=>x.id===id)
    }
    addUserElem = ()=>{
        let tempstate = this.state.cardData;
        tempstate.data.assigned_users.push({user:{id:""}, role:"", assigned_from:"", assigned_till:""})
        this.setState({cardData:tempstate})
        this.setState({editDisabled:false});
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.selectedId !== prevProps.selectedId || this.props.stateGetter("sidebarSelect") !== this.state.cardData.type) {

                let ts = this.state.cardData
                ts.type = this.props.stateGetter("sidebarSelect")
                ts.id = this.props.selectedId
                if(this.props.stateGetter(ts.type)!== undefined)
                    ts.data = this.props.stateGetter(ts.type).find(x => x.id == this.props.selectedId)

            if(this.props.selectedId ==='')
                this.setState({editDisabled:false})
            else
                this.setState({editDisabled:true})
            this.setState({cardData: ts})



            this.render()
            if(this.props.selectedId ==='new' && this.props.stateGetter("sidebarSelect")==="Clients")
                setTimeout(() => {this.toggleSubChecks()}, 300);
            setTimeout(() => {
                document.getElementsByTagName("textarea").forEach(x=>{textAreaAdjust(x);})
            }, 100)


        }
    }

    handleFormSubmit(e){
        e.preventDefault()

        if(this.props.stateGetter("sidebarSelect")==="Properties"){
            let data = {
                "deliveryId":e.target['deliveryId'].value,
                "property_name":e.target['property_name'].value,
                "property_id_sender":e.target['property_id_sender'].value,
                "property_id_receiver":e.target['property_id_receiver'].value,
                "rentroll":e.target['rentroll'].checked,
                "financial":e.target['financial'].checked,
                "esg":e.target['esg'].checked,
                "street":e.target['street'].value,
                "house_number":e.target['house_number'].value,
                "postal_code":e.target['postal_code'].value,
                "city":e.target['city'].value,
                "region":e.target['region'].value,
                "property_delivery_from":e.target['property_delivery_from'].value,
                "property_delivery_till":e.target['property_delivery_till'].value,
                assigned_users: [].slice.call(e.target.querySelectorAll("#MDUserInput > div.userline"),0).map(x=>{
                    return {
                        role: x.children[1].value,
                        assigned_from : x.children[2].value,
                        assigned_till : x.children[3].value,
                        userId: x.children[0].value,
                        category: (moment(x.children[2].value)>moment())?"future":(moment(x.children[3].value)<moment())?"past":"current"

                    }
                })
            }
            if(this.props.role!=="SUPERUSER")
                data["clientId"] =this.props.client.id
            else{
                data["clientId"] = e.target['clientId'].value
            }

            if (e.target.id != ''){
                data.id = e.target.id
                this.api.masterData.property.update(this.api.getCookie("jwt"),data).then((x)=>{
                    let i = this.props.stateGetter("Properties").findIndex(y=>y.id===x.data.id)
                    let tempProperty = this.props.stateGetter("Properties")
                    let tempUsers = x.data.assigned_users
                    if(tempUsers != undefined && tempUsers != null){
                        tempUsers.map(x=>{
                            x.category= (moment(x.assigned_from)>moment())?"future":(moment(x.assigned_till)<moment())?"past":"current";
                        })
                    }
                    Object.assign(x.data.assigned_users, tempUsers)

                    Object.assign(tempProperty[i],x.data)
                    this.props.stateSetter("Properties", tempProperty);
                    toast.success("Property Saved")
                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"
                })
                    .catch((error) => {
                        console.log(error)
                        toast.error(error.response.data.message)
                    })
            }
            else{
                this.api.masterData.property.save(this.api.getCookie("jwt"),data).then((x)=>{
                    let temp_card_data = this.state.cardData
                    temp_card_data.id = x.data.id
                    temp_card_data.data = x.data

                    this.setState({cardData:temp_card_data})
                    e.target.id = x.data.id
                    let tempProperty = this.props.stateGetter("Properties")
                    tempProperty.push(x.data)
                    this.props.stateSetter("Properties", tempProperty);
                    toast.success("Property Saved")

                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    this.props.stateSetter("selectedId",x.data.id)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"
                    // console.log(this.state.editDisabled,"xxxxxxxxxxxxxxxxxxx")
                })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                    })
            }
        }
        if(this.props.stateGetter("sidebarSelect")==="Users"){
            let data = {
                "first_name": e.target['first_name'].value,
                "last_name": e.target['last_name'].value,
                "email": e.target['email'].value,
                "phonenumber": e.target['phonenumber'].value,
                "roleList": e.target.querySelectorAll("input[type='checkbox']:checked").map(x=>x.name),
            }
            if(this.props.role!=="SUPERUSER"){
                data["clientId"]=this.props.client.id
            }
            else{
                data["clientId"]= e.target['clientId'].value
            }
            if (e.target.id != ''){
                data.id = e.target.id
                this.api.masterData.user.update(this.api.getCookie("jwt"),data).then((x)=>{
                    let i = this.props.stateGetter("Users").findIndex(y=>y.id===x.data.id)

                    let tempUser = this.props.stateGetter("Users")
                    tempUser[i]=x.data
                    this.props.stateSetter("Users", tempUser);
                    toast.success("User Saved")

                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"
                })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                    })
            }
            else{

                this.api.masterData.user.save(this.api.getCookie("jwt"),data).then((x)=>{
                    let temp_card_data = this.state.cardData
                    temp_card_data.id = x.data.id
                    temp_card_data.data = x.data
                    this.setState({cardData:temp_card_data})
                    e.target.id = x.data.id
                    let tempUser = this.props.stateGetter("Users")
                    tempUser.push(x.data)
                    this.props.stateSetter("Users", tempUser);
                    toast.success("User Saved")
                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    this.props.stateSetter("selectedId",x.data.id)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"
                })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                    })
            }

        }

        if(this.props.stateGetter("sidebarSelect")==="Deliveries"){
            let data = {}

            if(this.props.role!=="SUPERUSER")
                data["clientId"] =this.props.client.id
            else{
                data["clientId"] = e.target['clientId'].value
            }
            e.target.querySelectorAll("input").map(x=>{data[x.name]=x.value})
            if (e.target.id != ''){
                data.id = e.target.id
                this.api.masterData.delivery.update(this.api.getCookie("jwt"),data).then((x)=>{
                    let i = this.props.stateGetter("Deliveries").findIndex(y=>y.id===x.data.id)
                    let tempDelivery = this.props.stateGetter("Deliveries")
                    tempDelivery[i]=x.data
                    this.props.stateSetter("Deliveries", tempDelivery);
                    toast.success("Delivery Saved")
                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"

                })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                    })
            }
            else{
                this.api.masterData.delivery.save(this.api.getCookie("jwt"),data).then((x)=>{
                    let temp_card_data = this.state.cardData
                    temp_card_data.id = x.data.id
                    temp_card_data.data = x.data
                    this.setState({cardData:temp_card_data})
                    e.target.id = x.data.id
                    let tempDelivery = this.props.stateGetter("Deliveries")
                    tempDelivery.push(x.data)
                    this.props.stateSetter("Deliveries", tempDelivery);
                    toast.success("Delivery Saved")

                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    this.props.stateSetter("selectedId",x.data.id)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"
                })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                    })
            }
        }
        if(this.props.stateGetter("sidebarSelect")==="Clients"){
            let data = {
            }
            e.target.querySelectorAll("input[type='text']").map(x=>{data[x.name]=x.value})
            e.target.querySelectorAll("input[type='date']").map(x=>{data[x.name]=x.value})
            e.target.querySelectorAll("input[name='twoFactorAuthEnabled']").map(x=>{data[x.name]=x.checked})
            data.enabled_features = []
            e.target.querySelectorAll(".featuresCheck").map(x=>{if(x.checked)data.enabled_features.push(x.name)})

            data.upload_types = {
            }
            e.target.querySelectorAll(".topCheck").map(x=>{
                if(x.checked){
                    data.upload_types[x.name] = []
                    e.target.querySelectorAll("#"+x.name+" > .subCheck > div > input").map(y=>{
                        if(y.checked){
                            data.upload_types[x.name].push(y.name)
                        }
                    })
                }
            })




            if (e.target.id != ''){
                data.id = e.target.id
                this.api.masterData.client.update(this.api.getCookie("jwt"),data).then((x)=>{
                    let i = this.props.stateGetter("Clients").findIndex(y=>y.id===x.data.id)
                    let tempClient = this.props.stateGetter("Clients")
                    tempClient[i]=x.data
                    this.props.stateSetter("Clients", tempClient);
                    toast.success("Client Saved")

                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"
                })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                    })
            }
            else{
                this.api.masterData.client.save(this.api.getCookie("jwt"),data).then((x)=>{
                    let temp_card_data = this.state.cardData
                    temp_card_data.id = x.data.id
                    temp_card_data.data = x.data
                    this.setState({cardData:temp_card_data})
                    e.target.id = x.data.id
                    let tempClient = this.props.stateGetter("Clients")
                    tempClient.push(x.data)
                    this.props.stateSetter("Clients", tempClient);
                    toast.success("Client Saved")
                    let ts = this.state
                    ts.editDisabled = true
                    this.setState(ts)
                    this.props.stateSetter("selectedId",x.data.id)
                    document.getElementById("editBtn").style.display="block"
                    document.getElementById("saveBtn").style.display="none"
                })
                    .catch((error) => {
                        toast.error(error.response.data.message)
                    })
            }
        }
        this.render();
        }
    makeEditButtons=(d)=>{

        return(
            <div className="w3-xlarge" style={{float: 'right', display:"flex"}}>
                {   (d!==undefined)?
                    (d.deactivated)?
                    <i title={"activate"} style={{cursor:"pointer"}} onClick={()=>{this.deactivate(this.props.stateGetter("sidebarSelect"),d, false)}} className="fa fa-toggle-off"/>
                    :
                    <i title={"deactivate"} style={{cursor:"pointer"}} onClick={()=>{this.deactivate(this.props.stateGetter("sidebarSelect"),d, true)}} className="fa fa-toggle-on"/>

                    :
                    ""
                }
                <i className="fa fa-pencil-alt"style={{display:(!this.state.editDisabled)?"none":"block", cursor:"pointer"}} id={"editBtn"} onClick={(e)=>{this.setState({editDisabled:false}); e.target.style.display ="none"; document.getElementById("saveBtn").style.display="block";}}/>
                <button className="fa fa-save" style={{display:(this.state.editDisabled)?"none":"block", border:"none", cursor:"pointer"}} id="saveBtn" type={"submit"}/>
            </div>
        )
        return ""


    }


    deactivate=(type, data, deactivated)=>{

        let i;
        let tempObj;
        let id = data.id;
        let msg = "Please Confirm you want to "+((deactivated)?"de":"")+"activate."

        if (window.confirm(msg) !== true)
            return ""

        switch (type){
            case "Clients":
                this.api.masterData.client.deactivate(this.api.getCookie("jwt"),{id:id, deactivated:deactivated}).then((response) => {

                    toast.success("Client Status updated")
                    i = this.props.stateGetter(type).findIndex(y=>y.id===id)
                    tempObj = this.props.stateGetter(type)
                    tempObj[i].deactivated = deactivated
                    this.props.stateSetter(type, tempObj);

                }).catch((error) => {
                    console.error(error)
                    toast.error("Error: "+error.response.data.message)
                })


                break;
            case "Deliveries":
                this.api.masterData.delivery.deactivate(this.api.getCookie("jwt"),{id:id, deactivated:deactivated}).then((response) => {

                    toast.success("Delivery Status updated")
                    i = this.props.stateGetter(type).findIndex(y=>y.id===id)
                    tempObj = this.props.stateGetter(type)
                    tempObj[i].deactivated = deactivated
                    this.props.stateSetter(type, tempObj);

                }).catch((error) => {
                    console.error(error)
                    toast.error("Error: "+error.response.data.message)
                })
                break;
            case "Properties":
                this.api.masterData.property.deactivate(this.api.getCookie("jwt"),{id:id, deactivated:deactivated}).then((response) => {
                    toast.success(response.data.message.message)

                    let property = response.data.message.returnValue;
                    i = this.props.stateGetter(type).findIndex(y=>y.id===id)
                    tempObj = this.props.stateGetter(type)
                    Object.assign(tempObj[i], property)

                    this.props.stateSetter(type, tempObj);

                }).catch((error) => {
                    console.error(error)
                    toast.error("Error: "+error.response.data.message)
                })
                break;
            case "Users":
                data.properties.map(p=>{
                    p.assigned_users = p.assigned_users.filter(u=>
                        ((moment(u.assigned_from)>moment())?true:(moment(u.assigned_till)<moment())?false:true) &&
                        u.user.id === data.id
                    )
                    return p
                })
                data.properties = data.properties.filter(p=>
                    p.assigned_users.length > 0
                )

                if(data.properties.length >0 && deactivated === true) {
                    msg = "User "+ data.first_name+ " "+ data.last_name+ " is assigned to following properties:\n"+ data.properties.reduce((p,c)=>p+c.property_name+"\n","")+
                        "\nPlease confirm you really want to deactivate."

                    if (window.confirm(msg) !== true)
                        return ""
                }
                this.api.masterData.user.deactivate(this.api.getCookie("jwt"),{id:id, deactivated:deactivated}).then((response) => {

                    toast.success("User Status updated")
                    i = this.props.stateGetter(type).findIndex(y=>y.id===id)
                    tempObj = this.props.stateGetter(type)
                    tempObj[i].deactivated = deactivated
                    this.props.stateSetter(type, tempObj);

                }).catch((error) => {
                    console.error(error)
                    toast.error("Error: "+error.response.data.message)
                })
                break;

        }
    }

    //resendVerification
    resendVerification = (id) => {
        let i = this.props.stateGetter("Users").findIndex(y=>y.id===id)
        let globalUsers = this.props.stateGetter("Users")
        let payload = {
            email: globalUsers[i].email
        }

        this.api.masterData.user.resendVerification(this.api.getCookie("jwt"), payload).then((response) => {

            
            console.log(response)

            toast.success("Resend Verification!")
        }).catch((error) => {
            console.error(error)
            toast.error("Error: "+error.response.data.message)
        })
    }

    render(){
        let d = this.state.cardData.data
        console.log(this.state.cardData)

        if(this.props.stateGetter("selectedId")!=="new" && this.state.cardData.data=== undefined)
        {
            return""
        }
        if(this.state.cardData.data=== undefined && this.state.editDisabled===true){
            this.setState({editDisabled:false})
        }


        switch (this.state.cardData.type){
            case "Users":
                if (d === undefined || Object.keys(d).length === 0)
                    d = {
                        roles:[],
                        clients:[]
                    }
                let roles = (d.roles!==null)
                    ?
                    d.roles.map( x => ( x != null) ? x.role : "")
                    :
                    []
                let clients = (d.clients!==null)
                    ?
                    d.clients.map( x => x !== null ? x.name : "")
                    :
                    []
                // console.log("rrrrrroooooollllleees", roles)

                let properties = d.properties
                if(properties!==undefined && properties !== null) {
                    properties.map(p => {
                        p.assigned_users = p.assigned_users.filter(u =>
                            ((moment(u.assigned_from) > moment()) ? true : (moment(u.assigned_till) < moment()) ? false : true) &&
                            u.user.id === d.id
                        )
                        return p
                    })
                    properties = properties.filter(p =>
                        p.assigned_users.length > 0
                    )
                }
                let  assigned_properties = []
                if(properties!==undefined && properties !== null)
                    assigned_properties = [].concat.apply([], properties.map(p=>p.assigned_users.map(u=>{let o = {}; o[u.role] = p.property_name+" ("+p.property_id_sender+")"; return o})))
                let property_roles = [...new Set(assigned_properties.map(p=>Object.keys(p)[0]))]
                let property_assignments = {}

                property_roles.map(r=>{
                    property_assignments[r] = assigned_properties.filter(p=>Object.keys(p)[0] ===r).map(a=>{return a[Object.keys(a)[0]]})
                })
                console.log(d,"dd")
                let alertStyle = {}
                return(
                    <form key={d.id+"User"} id={d.id} name = "mdForm" onSubmit={this.handleFormSubmit} className="w3-container mdformcontainer">
                        <div className="w3-container w3-card-4 w3-left-align w3-round-xlarge datacard" style={{overflowY:"hidden"}}>
                            {
                                (!d.verified && this.props.stateGetter("role")!=="SUPERUSER" && !(d.id===undefined))
                                    ?
                                    <AlertWarningWithDissmiss>
                                        <button type={"button"} className={styles.resendButton} onClick={() => this.resendVerification(d.id)}>Resend E-Mail</button>
                                        <span>User has not completed registration yet!</span>
                                    </AlertWarningWithDissmiss>
                                    :
                                    <></>
                            }
                            {this.makeEditButtons(d)}

                            <h2><input disabled={this.state.editDisabled} className={"mdInput"} placeholder={"Lastname"} name={"last_name"} type="text" defaultValue={d.last_name}></input></h2>
                            <h4><input disabled={this.state.editDisabled} className={"mdInput"} placeholder={"Firstname"} name={"first_name"} type="text" defaultValue={d.first_name}></input></h4>
                            <hr style={{borderColor:this.props.roleColor}} className="w3-round-xlarge"/>

                            {
                                (this.props.stateGetter("role") === "SUPERUSER")?
                                    <div>

                                    <h3>Client</h3>
                                    <div className="w3-row-padding cardline">
                                        <div className="w3-quarter">Client</div>
                                        <div className="w3-threequarter"><select name={"clientId"} disabled={this.state.editDisabled} defaultValue={(d.clients===undefined|| d.clients===null || d.clients.length===0)?"":d.clients[0].id} className={"mdInput"} type="">
                                            {this.props.stateGetter("Clients").map(c=><option value={c.id}>{c.name}</option>)}
                                            <option value={""}>SUPERUSER</option>
                                        </select>
                                        </div>
                                    </div>
                                    </div>

                                    :<></>
                            }
                            <h3>Contact</h3>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">Email</div>
                                <div className="w3-threequarter"><input name={"email"} disabled={this.state.editDisabled} className={"mdInput"} type="email" defaultValue={d.email}/></div>
                            </div>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">Telephone</div>
                                <div className="w3-threequarter"><input name={"phonenumber"} disabled={this.state.editDisabled} className={"mdInput"} type="text" defaultValue={d.phonenumber}/></div>
                            </div>
                            <h3>Roles</h3>

                            {
                                ["ADMIN", "SPV", "CO", "AM", "PM"].map((role, key) => {
                                    return(
                                        <div className="w3-row-padding cardline">
                                            <div className="w3-quarter">{role}</div>
                                            <div className="w3-threequarter"><input className={"mdInput"} type="checkbox" id={d.id+role} name={role} defaultChecked={roles.includes(role)?true: false} disabled={this.state.editDisabled}/></div>
                                        </div>
                                    )
                                })
                            }
                            {
                                (this.props.stateGetter("role") === "SUPERUSER")
                                    ?
                                    <>
                                        {
                                            ["SUPERUSER", "API"].map((role, key) => {
                                                return(
                                                    <div className="w3-row-padding cardline">
                                                        <div className="w3-quarter">{role}</div>
                                                        <div className="w3-threequarter"><input className={"mdInput"} type="checkbox" id={d.id+role} name={role} defaultChecked={roles.includes(role)?true: false} disabled={this.state.editDisabled}/></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                    :
                                    <></>
                            }



                                <h3>Assigned Properties</h3>
                                <div className="w3-row-padding cardline">
                                    {
                                        Object.keys(property_assignments).map(role=>
                                            <><div className="w3-quarter" style={{paddingTop:"8px"}}>{role}</div>
                                                <div className="w3-threequarter"  style={{paddingTop:"8px"}}>
                                                    {[...new Set(property_assignments[role])].map(p =>
                                                        <span>{p}<br/></span>)
                                                    }
                                                </div>
                                            </>

                                        )
                                    }

                                </div>
                        </div>
                    </form>
            )
                break;
            case "Deliveries":
                if (d === undefined || Object.keys(d).length === 0)
                    d={
                    }
                return(
                    <form key={d.id+"Delivery"} id={d.id} onSubmit={this.handleFormSubmit} className="w3-container mdformcontainer">

                        <div className="w3-container w3-card-4 w3-left-align w3-round-xlarge datacard" style={{overflowY:"hidden"}}>
                            {this.makeEditButtons(d)}

                            <h2><input disabled={this.state.editDisabled} className={"mdInput"} placeholder={"Fund Name"} name={"fond_name"} type="text" defaultValue={d.fond_name}></input></h2>
                            <hr style={{borderColor:this.props.roleColor}} className="w3-round-xlarge"/>
                            <input disabled={this.state.editDisabled} className={"mdInput"} name={"fond_id"} placeholder={"Fund ID"} type="text" defaultValue={d.fond_id}></input>
                            {
                                (this.props.stateGetter("role") === "SUPERUSER")?
                                    <div>

                                        <h3>Client</h3>
                                        <div className="w3-row-padding cardline">
                                            <div className="w3-quarter">Client</div>
                                            <div className="w3-threequarter"><select name={"clientId"} disabled={this.state.editDisabled} defaultValue={d.clientId} className={"mdInput"} type="">
                                                {this.props.stateGetter("Clients").map(c=><option value={c.id}>{c.name}</option>)}
                                            </select>
                                            </div>
                                        </div>
                                    </div>

                                    :<></>
                            }
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">Rentroll</div>
                                <div className="w3-threequarter"><input placeholder={"0"} name = {"delivery_days_rentroll"} disabled={this.state.editDisabled} className={"mdInput"} type="number" defaultValue={d.delivery_days_rentroll}/></div>
                            </div>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">Financial</div>
                                <div className="w3-threequarter"><input placeholder={"0"} name = {"delivery_days_financial"} disabled={this.state.editDisabled} className={"mdInput"} type="number" defaultValue={d.delivery_days_financial}/></div>
                            </div>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">ESG</div>
                                <div className="w3-threequarter"><input placeholder={"0"} name = {"delivery_days_esg"} disabled={this.state.editDisabled} className={"mdInput"} type="number" defaultValue={d.delivery_days_esg}/></div>
                            </div>


                        </div>
                    </form>
                )
                break;
            case "Properties":
                if (d === undefined || Object.keys(d).length === 0)
                    d={
                    delivery:{},
                    assigned_users:[]
                    }
                if(d.assigned_users)
                if(d.assigned_users.length)
                if(this.props.stateGetter("Deliveries").length === 0){

                    toast.error("Please set up Deliveries before editing Properties.")
                    return "";
                }


                let del_client_id = ""
                if(this.props.role==="SUPERUSER")
                    del_client_id = (d.client!== undefined && d.client.id!== undefined )?d.client.id:this.props.stateGetter("Clients")[0].id

                return(
                    <form key={d.id+"Property"} id={d.id} onSubmit={this.handleFormSubmit} className="w3-container mdformcontainer">

                    <div className="w3-container w3-card-4 w3-left-align w3-round-xlarge datacard" style={{overflowY:"hidden"}}>
                        {this.makeEditButtons(d)}

                        <h2><textarea placeholder={"Property Name"} onKeyUp={textAreaAdjustEvent} disabled={this.state.editDisabled} className={"mdInput"} type="text" defaultValue={d.property_name} name={"property_name"}></textarea></h2>
                        <hr style={{borderColor:this.props.roleColor}} className="w3-round-xlarge"/>

                        {
                            (this.props.stateGetter("role") === "SUPERUSER")?
                                <div>

                                    <h3>Client</h3>
                                    <div className="w3-row-padding cardline">
                                        <div className="w3-quarter">Client</div>
                                        <div className="w3-threequarter"><select name={"clientId"} disabled={this.state.editDisabled} defaultValue={d.client===undefined?"":d.client.id} className={"mdInput"} onChange={(e)=>{document.forms[0].getElementsByClassName("deliveryId")[0].children.forEach(
                                            x=>{if(x.attributes.clientId.value==e.target.value){x.hidden = false; x.disabled = false;}else{x.hidden = true;  x.disabled = true; document.forms[0].getElementsByClassName("deliveryId")[0].value = ""}}
                                        )}}>
                                            {this.props.stateGetter("Clients").map(c=><option value={c.id}>{c.name}</option>)}
                                        </select>
                                        </div>
                                    </div>
                                </div>

                                :<></>
                        }

                        <h3>IDs</h3>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">ID Receiver</div>
                            <div className="w3-threequarter"><input required={true} disabled={this.state.editDisabled} className={"mdInput"} type="text" name={"property_id_receiver"}defaultValue={d.property_id_receiver}/></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">ID Sender</div>
                            <div className="w3-threequarter"><input required={true} disabled={this.state.editDisabled} className={"mdInput"} type="text" name={"property_id_sender"}defaultValue={d.property_id_sender}/></div>
                        </div>
                        <h3>Address</h3>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Street</div>
                            <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="text" name={"street"}defaultValue={d.street}/></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Number</div>
                            <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="text" name={"house_number"}defaultValue={d.house_number}/></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Postal Code</div>
                            <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="text" name={"postal_code"}defaultValue={d.postal_code}/></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">City</div>
                            <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="text" name={"city"}defaultValue={d.city}/></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Country</div>
                            <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="text" name={"region"}defaultValue={d.region}/></div>
                        </div>
                        <h3>Delivery</h3>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Delivery ID</div>
                            <div className="w3-threequarter"><select disabled={this.state.editDisabled} className={"mdInput deliveryId"} style={{minWidth:"176px"}} name={"deliveryId"}defaultValue={(d.delivery.id!==undefined)?d.delivery.id:""} type="text" >
                                <option selected={d.delivery.id!==undefined} value={""} disabled > Select Delivery</option>
                                {
                                    (this.props.stateGetter("role") === "SUPERUSER")?
                                        this.props.stateGetter("Deliveries").map(x=>{
                                            return(<option disabled = {(x.clientId !== del_client_id)?true:false} value={x.id} clientId={x.clientId} id={x.id}>{x.fond_name} ({x.fond_id}) {(x.deactivated)?" (deactivated)":""}</option>)
                                        })
                                        :
                                        this.props.stateGetter("Deliveries").map(x=>{
                                            return(<option value={x.id} clientId={x.clientId} id={x.id}>{x.fond_name} ({x.fond_id}) {x.deactivated?" (deactivated)":""}</option>)
                                        })
                                }
                            </select></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">From:</div>
                            <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"}  style={{minWidth:"176px"}} type="date" name={"property_delivery_from"}defaultValue={d.property_delivery_from} disabled={this.state.editDisabled}/>
                            </div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Till:</div>
                            <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"}  style={{minWidth:"176px"}} type="date" name={"property_delivery_till"}defaultValue={d.property_delivery_till} disabled={this.state.editDisabled}/>
                            </div>
                        </div>
                        <h3>Checks</h3>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">ESG</div>
                            <div className="w3-threequarter"><input type="checkbox" defaultChecked={d.esg} name={"esg"} className={"mdInput"} disabled={this.state.editDisabled}/></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Financial</div>
                            <div className="w3-threequarter"><input type="checkbox" defaultChecked={d.financial} name={"financial"} className={"mdInput"} disabled={this.state.editDisabled}/></div>
                        </div>
                        <div className="w3-row-padding cardline">
                            <div className="w3-quarter">Rentroll</div>
                            <div className="w3-threequarter"><input type="checkbox" defaultChecked={d.rentroll} name={"rentroll"} className={"mdInput"} disabled={this.state.editDisabled}/></div>
                        </div>

                        <h3>Assigned Users</h3>
                        <MDUserInput  editDisabled={this.state.editDisabled}  maxDate = {d.property_delivery_till} dbUsers ={d.assigned_users} globalUsers = {this.props.stateGetter("Users")}></MDUserInput>


                    </div>
                    </form>
                )
                break;
            case "Clients":
                if (d === undefined || Object.keys(d).length === 0)
                    d={

                    }

                return(
                    <form key={d.id+"User"} id={d.id} onSubmit={this.handleFormSubmit} className="w3-container mdformcontainer">

                        <div className="w3-container w3-card-4 w3-left-align w3-round-xlarge datacard" style={{overflowY:"hidden"}}>
                            <div className="w3-xlarge" style={{float: 'right', display:'inline-flex'}}>
                                {this.makeEditButtons(d)}
                            </div>
                            <h2><input required={true} disabled={this.state.editDisabled} className={"mdInput"} placeholder={"name"} type="text" defaultValue={d.name} name={"name"}></input></h2>
                            <hr style={{borderColor:this.props.roleColor}} className="w3-round-xlarge"/>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">valid_from</div>
                                <div className="w3-threequarter"><input placeholder={"YYYY-MM-DD"} disabled={this.state.editDisabled} className={"mdInput"} type="date" defaultValue={d.valid_from} name={"valid_from"}/></div>
                            </div>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">valid_till</div>
                                <div className="w3-threequarter"><input placeholder={"YYYY-MM-DD"} disabled={this.state.editDisabled} className={"mdInput"} type="date" defaultValue={d.valid_till} name={"valid_till"}/></div>
                            </div>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">contact_person</div>
                                <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="text" defaultValue={d.contact_person} name={"contact_person"}/></div>
                            </div>
                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">contact_email</div>
                                <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="text" defaultValue={d.contact_person_email} name={"contact_person_email"}/></div>
                            </div>

                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">enabled_features</div>
                                <div className="w3-threequarter">
                                    {/*<div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkFeatureChecks(d,"DEFAULT")} className={"featuresCheck mdInput"} name={"DEFAULT"}/> <label>DEFAULT</label></div>*/}
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkFeatureChecks(d,"QUALITY")} className={"featuresCheck mdInput"} name={"QUALITY"}/> <label>QUALITY</label></div>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkFeatureChecks(d,"ANALYZE")} className={"featuresCheck mdInput"} name={"ANALYZE"}/> <label>ANALYZE</label></div>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkFeatureChecks(d,"CHANGE_REPORTING")} className={"featuresCheck mdInput"} name={"CHANGE_REPORTING"}/> <label>CHANGE_REPORTING</label></div>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkFeatureChecks(d,"ESG_DASHBOARD")} className={"featuresCheck mdInput"} name={"ESG_DASHBOARD"}/> <label>ESG_DASHBOARD</label></div>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkFeatureChecks(d,"DEMO_CLIENT")} className={"featuresCheck mdInput"} name={"DEMO_CLIENT"}/> <label>DEMO_CLIENT</label></div>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkFeatureChecks(d,"DATA_EXPORT")} className={"featuresCheck mdInput"} name={"DATA_EXPORT"}/> <label>DATA_EXPORT</label></div>
                              </div>
                            </div>

                            <div className="w3-row-padding cardline">
                                <div className="w3-quarter">2FA for all Users</div>
                                <div className="w3-threequarter"><input disabled={this.state.editDisabled} className={"mdInput"} type="checkbox" defaultChecked={d.twoFactorAuthEnabled} name={"twoFactorAuthEnabled"}/></div>
                            </div>

                            <div className={"w3-row-padding"}>
                                <div className={"w3-third"} onClick={this.toggleSubChecks} id={"FINANCIAL"}>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} name={"FINANCIAL"} defaultChecked={this.checkTopChecks(d,"FINANCIAL")} className={"topCheck mdInput w3-check"}></input> <b>Financial</b></div>
                                    <div className={"subCheck"}>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"FINANCIAL","PERIOD")} name={"PERIOD"} className={"w3-check"}></input> PERIOD</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"FINANCIAL","COMPANY")} name={"COMPANY"} className={"w3-check"}></input> COMPANY</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"FINANCIAL","PROPERTY")} name={"PROPERTY"} className={"w3-check"}></input> PROPERTY</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"FINANCIAL","ACCOUNT")} name={"ACCOUNT"} className={"w3-check"}></input> ACCOUNT</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"FINANCIAL","BOOKENTRY")} name={"BOOKENTRY"} className={"w3-check"}></input> BOOKENTRY</div>
                                    </div>
                                </div>
                                <div className={"w3-third"} onClick={this.toggleSubChecks} id={"ESG"}>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} name={"ESG"} defaultChecked={this.checkTopChecks(d,"ESG")} className={"topCheck mdInput w3-check"}></input> <b>ESG</b></div>
                                    <div className={"subCheck"}>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","PERIOD")} name={"PERIOD"} className={"w3-check"}></input> PERIOD</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","COMPANY")} name={"COMPANY"} className={"w3-check"}></input> COMPANY</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","PROPERTY")} name={"PROPERTY"} className={"w3-check"}></input> PROPERTY</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","CONSUMPTION")} name={"CONSUMPTION"} className={"w3-check"}></input> CONSUMPTION</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","MEASUREMENT")} name={"MEASUREMENT"} className={"w3-check"}></input> MEASUREMENT</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","BUILDING")} name={"BUILDING"} className={"w3-check"}></input> BUILDING</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","UNIT")} name={"UNIT"} className={"w3-check"}></input> UNIT</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"ESG","UNITLEASED")} name={"UNITLEASED"} className={"w3-check"}></input> UNITLEASED</div>
                                    </div>
                                </div>

                                <div className={"w3-third"} onClick={this.toggleSubChecks} id={"RENTROLL"}>
                                    <div><input type={"checkbox"} disabled={this.state.editDisabled} name={"RENTROLL"} defaultChecked={this.checkTopChecks(d,"RENTROLL")} className={"topCheck mdInput w3-check"}></input> <b>Rentroll</b></div>
                                    <div className={"subCheck"}>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","PERIOD")} name={"PERIOD"} className={"w3-check"}></input> PERIOD</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","COMPANY")} name={"COMPANY"} className={"w3-check"}></input> COMPANY</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","LEASE")} name={"LEASE"} className={"w3-check"}></input> LEASE</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","PARTNER")} name={"PARTNER"} className={"w3-check"}></input> PARTNER</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","PARTNERLINK")} name={"PARTNERLINK"} className={"w3-check"}></input> PARTNERLINK</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","PROPERTY")} name={"PROPERTY"} className={"w3-check"}></input> PROPERTY</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","TERM")} name={"TERM"} className={"w3-check"}></input> TERM</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","UNIT")} name={"UNIT"} className={"w3-check"}></input> UNIT</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","UNITLEASED")} name={"UNITLEASED"} className={"w3-check"}></input> UNITLEASED</div>
                                        <div><input type={"checkbox"} disabled={this.state.editDisabled} defaultChecked={this.checkSubChecks(d,"RENTROLL","BUILDING")} name={"BUILDING"} className={"w3-check"}></input> BUILDING</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )
                break;
        }


        return (
            <div className="w3-center" id="card-container" style={{width: 'calc(100% - 250px)', float: 'right', height: 'calc(100% - 50px)', paddingTop: '16px'}}>
                <div className="w3-container w3-card-4 w3-left-align w3-round-xlarge datacard" style={{minHeight:"50vh",overflowY:"scroll"}}>
                    <div className="w3-xlarge" style={{float: 'right'}}>
                        <i className="fa fa-trash" />
                        <i className="fa fa-pencil" />
                    </div>
                    <h2>{this.state.cardData.id}</h2>
                    <hr className="w3-border-red w3-round-xlarge" />
                </div>
            </div>
        );
    }
}

export default MasterData;